import { EventEmitter, Injectable } from '@angular/core';

import * as ng from '@angular/core';

import { PopupContainerComponent } from '../components/popup-container/popup-container.component';

import { PopupOptions } from '../models/popup-options.model';

import { ComponentLoaderFactoryService } from './component-loader-factory.service';
import { ComponentLoaderService } from './component-loader.service';
import { PopupRefService } from './popup-ref.service';

@Injectable()
export class PopupService {
  private readonly _renderer: ng.Renderer2;
  private readonly _componentLoaderFactory: ComponentLoaderFactoryService;

  private readonly _loaders: Array<ComponentLoaderService<PopupContainerComponent>>;
  private readonly _config: PopupOptions;

  constructor(rendererFactory: ng.RendererFactory2, componentLoaderFactory: ComponentLoaderFactoryService) {
    this._renderer = rendererFactory.createRenderer(null, null);
    this._componentLoaderFactory = componentLoaderFactory;
    this._loaders = [];
    this._config = new PopupOptions();
  }

  show(content: ng.TemplateRef<any> | ng.Type<any> | string, config?: PopupOptions): PopupRefService {
    this._createLoaders();
    return this._showPopup(content, {...this._config, ...config});
  }

  hide(id?: number): void {
    this._hidePopup(id);
    this._removeLoader(id);
  }

  private _createLoaders(): void {
    const loader = this._componentLoaderFactory.createLoader<PopupContainerComponent>(null, null, null);
    this._loaders.push(loader);
  }

  private _removeLoader(id?: number): void {
    if (typeof id !== 'number') {
      this._loaders.splice(0, this._loaders.length);
      return;
    }

    const idx = this._loaders.findIndex(x => x.instance.config.id === id);
    if (0 <= idx) {
      this._loaders.splice(idx, 1);
    }
  }

  private _showPopup(content: ng.TemplateRef<any> | ng.Type<any> | string, config: PopupOptions): PopupRefService {
    const loader = this._loaders[this._loaders.length - 1];
    if (this._config && this._config.providers) {
      for (let i = 0, len = this._config.providers.length; i < len; i++) {
        loader.provide(this._config.providers[i]);
      }
    }

    const popupRef = new PopupRefService();

    const popupContainerRef = loader
      .provide({provide: PopupRefService, useValue: popupRef})
      .provide({provide: PopupOptions, useValue: {...config, popupService: this, id: this._loaders.length - 1}})
      .attach(PopupContainerComponent);

    popupContainerRef.show(content, null, config.injectableData);

    popupRef.hide = () => popupContainerRef.instance.hide();
    popupRef.hideAll = () => {
      for (let i = this._loaders.length - 1; 0 <= i; i--) {
        this._loaders[i].instance.hide();
      }
    };

    popupRef.repaint = () => popupContainerRef.instance.repaint();

    return popupRef;
  }

  private _hidePopup(id?: number): void {
    if (typeof id !== 'number') {
      for (let i = 0, len = this._loaders.length; i < len; i++) {
        const loader = this._loaders[i];
        loader.hide(loader.instance.config.id);
      }

      return;
    }

    const idx = this._loaders.findIndex(x => x.instance.config.id === id);
    if (0 <= idx) {
      const loader = this._loaders[idx];
      loader.hide(loader.instance.config.id);
    }
  }
}
