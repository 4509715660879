import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DxDataGridComponent } from 'devextreme-angular';
import { DxiItemComponent } from 'devextreme-angular/ui/nested';
import * as AspNetData from 'devextreme-aspnet-data-nojquery';
import ArrayStore from 'devextreme/data/array_store';
import notify from 'devextreme/ui/notify';
import { take, takeUntil, tap } from 'rxjs/operators';

import { environment } from 'src/environments/environment';

import { AuthService } from 'src/app/shared/services';
import { PopupService } from '../../../infrastructure/services/popup.service';
import { BuildingService } from '../../services/building.service';

import { ImportComponent } from '../import/import.component';

import { Tools } from 'src/app/shared/tools';

import * as vm from 'src/app/shared/models/generated';
import { combineLatest, forkJoin, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';

const GREEN_CERTIFICATIONS = [
  { id: 1, text: 'Leed Certified', type: vm.GreenCertification.LeedCertified },
  { id: 2, text: 'Leed Silver', type: vm.GreenCertification.LeedSilver },
  { id: 3, text: 'Leed Gold', type: vm.GreenCertification.LeedGold },
  { id: 4, text: 'Leed Platinum', type: vm.GreenCertification.LeedPlatinum },
  { id: 5, text: 'Energy Star', type: vm.GreenCertification.EnergyStar },
  { id: 6, text: 'Breeam', type: vm.GreenCertification.Breeam },
  { id: 7, text: 'Green Globe', type: vm.GreenCertification.GreenGlobe },
  { id: 14, text: 'Green Globe Gold', type: vm.GreenCertification.GreenGlobeGold },
  { id: 15, text: 'Green Globe Platinum', type: vm.GreenCertification.GreenGlobePlatinum },
  { id: 8, text: 'Living Building Challenge', type: vm.GreenCertification.LivingBuildingChallenge },
  { id: 9, text: 'National Green Building Standard', type: vm.GreenCertification.NationalGreenBuildingStandard },
  { id: 10, text: 'Green Guard', type: vm.GreenCertification.GreenGuard },
  { id: 11, text: 'Well Building Standard', type: vm.GreenCertification.WellBuildingStandard },
  { id: 12, text: 'Fitwell', type: vm.GreenCertification.Fitwell },
];

@Component({
  selector: 'app-building-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
})
export class BuildingListComponent implements OnInit, OnDestroy {
  @ViewChild('dxDataGrid', {static: false}) dxDataGridComponent: DxDataGridComponent;
  @ViewChild('subMarketSelectBox', {static: false}) subMarketSelectBox: DxiItemComponent;

  subMarketEditorEl: any;

  dataSource: any;
  portfoliosDataSource: any;
  landlordCompaniesDataSource: any;
  constructionTypeDataSource: any;
  marketsDataSource: any;
  subMarketsDataSource: any;
  BuildingClass = vm.BuildingClass;
  buildingClasses = Tools.EnumToArray(vm.BuildingClass);
  buildingTypes = Tools.EnumToArray(vm.BuildingType);
  buildingLoadingConfigurations = Tools.EnumToArray(vm.BuildingLoadingConfiguration);
  sprinklerSystems = Tools.EnumToArray(vm.SprinklerSystem);

  selectedCertificates = [];
  greenCertifications = [...GREEN_CERTIFICATIONS];
  greenCertificationsDataSource: any = new ArrayStore({
    data: this.greenCertifications,
    key: 'id',
  });

  get _window() {
    return window;
  }

  Tools = Tools;
  environment = environment;
  url = `${environment.adminUrl}/building`;
  currentAddress = {} as vm.IAddress;

  currentBuilding: vm.IBuilding;
  currentBuildingKey: number;

  /* address form select boxes items */
  allAssetsTypes: vm.IAssetsType[] = [];
  states: vm.IState[] = [];
  countries: vm.ICountry[] = [];
  counties: vm.ICounty[] = [];
  allCounties: vm.ICounty[] = [];
  markets: vm.IMarket[] = [];
  allMarkets: vm.IMarket[] = [];
  subMarkets: vm.IMarket[] = [];
  allSubMarkets: vm.IMarket[] = [];
  /* address form editors instances */
  addressLine1: any;
  addressLine2: any;
  zipCode: any;
  city: any;
  countryCode: any;
  stateCode: any;
  countyName: any;
  marketId: any;
  subMarketId: any;
  /* address form editors configurations */
  addressLine1EditorOptions: any;
  addressLine2EditorOptions: any;
  zipCodeEditorOptions: any;
  cityEditorOptions: any;
  countryEditorOptions: any;
  assetsTypeEditorOptions: any;
  stateEditorOptions: any;
  countyEditorOptions: any;
  marketEditorOptions: any;
  subMarketEditorOptions: any;
  addressEditor: any;
  addressEditorOptions = {
    visible: false,
    onInitialized: e => (this.addressEditor = e.component),
  };
  greenCertificationsEditorOptions: any;

  popupVisible = false;

  private readonly _authService: AuthService;
  private readonly _popupService: PopupService;
  private readonly _buildingService: BuildingService;
  private readonly _httpClient: HttpClient;

  private readonly _destroy: Subject<void>;

  constructor(
    authService: AuthService,
    popupService: PopupService,
    buildingService: BuildingService,
    httpClient: HttpClient,
  ) {
    this._authService = authService;
    this._popupService = popupService;
    this._buildingService = buildingService;
    this._httpClient = httpClient;

    this._destroy = new Subject<void>();

    this.customizeItem = this.customizeItem.bind(this);
  }

  addressFormFieldDataChanged() {
    this.addressEditor.option('value', this.currentAddress);
  }

  ngOnInit() {
    combineLatest([
      this._authService.addressPropertiesCompletes$,
      this._httpClient
        .get<{data: vm.IAssetsType[]}>(
          `${environment.adminUrl}/assetsType`,
          { withCredentials: true },
        ),
    ])
      .pipe(
        tap(([addressProperties, assetsTypes]) => {
          if (addressProperties && assetsTypes) {
            this.countries = addressProperties.countries;
            this.states = addressProperties.states;
            this.allCounties = addressProperties.counties;
            this.allMarkets = addressProperties.markets;
            this.allSubMarkets = addressProperties.subMarkets;
            this.allAssetsTypes = assetsTypes.data;
            this.setEditorOptions();
          }
        }),
        takeUntil(this._destroy),
      )
      .subscribe();

    this.dataSource = AspNetData.createStore({
      key: 'id',
      loadUrl: this.url,
      insertUrl: this.url,
      updateUrl: this.url,
      deleteUrl: this.url,
      onBeforeSend: (method, ajaxOptions) => {
        ajaxOptions.xhrFields = {withCredentials: true};
        if (ajaxOptions.data.values) {
          this.prepareSave(ajaxOptions);
        }
      },
    });

    this.landlordCompaniesDataSource = Tools.lookupDataSource('company?companyType=LandlordCompany');
    this.portfoliosDataSource = Tools.lookupDataSource('portfolio');
    this.constructionTypeDataSource = Tools.lookupDataSource('constructionType');

    this.marketsDataSource = AspNetData.createStore({
      key: 'id',
      loadUrl: `${environment.adminUrl}/market`,
      onBeforeSend: (method, ajaxOptions) => {
        ajaxOptions.xhrFields = {withCredentials: true};
      },
    });

    this.subMarketsDataSource = AspNetData.createStore({
      key: 'id',
      loadUrl: `${environment.adminUrl}/market/child`,
      onBeforeSend: (method, ajaxOptions) => {
        ajaxOptions.xhrFields = {withCredentials: true};

        if (this.marketId) {
          ajaxOptions.data.parentMarketId = this.marketId;
        }
      },
    });
  }

  ngOnDestroy(): void {
    this._destroy.next();
    this._destroy.complete();
  }

  prepareSave(ajaxOptions) {
    const values = JSON.parse(ajaxOptions.data.values);
    delete values.address;
    delete values.market;
    delete values.subMarket;
    delete values.picture;
    delete values.portfolio;
    delete values.leases;
    delete values.createdBy;

    const certIds = values.greenCertificates || [];

    ajaxOptions.data.greenCertificates = JSON.stringify(
      this.greenCertifications
        .filter(x => certIds.includes(x.id))
        .map(x => ({ certificationType: x.type, customValue: x.type === vm.GreenCertification.Other ? x.text : null }))
    );

    delete values.greenCertificates;

    ajaxOptions.data.values = JSON.stringify(values);

    const address = {...this.currentAddress};
    delete address.county;
    delete address.state;

    ajaxOptions.data.address = JSON.stringify(address);
  }

  onRowPrepared(e): void {
    if (e.rowType === 'data' && e.data && e.data.deletedOn && e.rowElement) {
      e.rowElement.classList.add('is-deleted');
    }
  }

  calculateAssetsTypeCellValue(e): string {
    return e.assetsTypeId;
  }

  calculateAssetsTypeDisplayValue(e): string {
    return e.assetsType?.name;
  }

  setEditorOptions() {
    const options = {
      onInitialized: e => {
        const instance = e.component.instance();
        this[instance.option('name')] = instance;
      },
    };

    this.addressLine1EditorOptions = options;
    this.addressLine2EditorOptions = options;
    this.zipCodeEditorOptions = options;
    this.cityEditorOptions = options;
    this.countryEditorOptions = options;
    this.assetsTypeEditorOptions = options;
    this.stateEditorOptions = options;
    this.countyEditorOptions = options;
    this.marketEditorOptions = options;
    this.subMarketEditorOptions = options;

    this.assetsTypeEditorOptions = {
      ...this.assetsTypeEditorOptions,
      displayExpr: 'name',
      valueExpr: 'id',
      items: this.allAssetsTypes.map((x: any) => {
        x.key = x.name;
        x.items = x.childAssetsTypes;
        return x;
      }),
      placeholder: 'Select assets type',
      grouped: true,
      closeOnOutsideClick: true,
      showPopupTitle: false,
      showClearButton: true,
    };

    this.stateEditorOptions = {
      ...this.stateEditorOptions,
      items: this.states,
      displayExpr: 'name',
      valueExpr: 'stateCode',
      onValueChanged: e => {
        this.onStateChange(e);
      },
      showClearButton: true,
    };

    this.marketEditorOptions = {
      ...this.marketEditorOptions,
      displayExpr: 'name',
      valueExpr: 'id',
      onValueChanged: e => {
        this.marketId = e.value;
        this.subMarketEditorEl.setValue(null);
        this.subMarketSelectBox.instance?.getDataSource().load();
      },
      showClearButton: false,
    };

    this.subMarketEditorOptions = {
      ...this.subMarketEditorOptions,
      displayExpr: 'name',
      valueExpr: 'id',
      showClearButton: true,
    };

    this.countyEditorOptions = {
      ...this.countyEditorOptions,
      items: this.counties,
      displayExpr: 'name',
      valueExpr: 'name',
      showClearButton: true,
    };

    this.countryEditorOptions = {
      ...this.countryEditorOptions,
      items: this.countries,
      displayExpr: 'name',
      valueExpr: 'countryCode',
      showClearButton: true,
    };
  }

  onEditorPreparing(e) {
    if (e.parentType === 'dataRow' && e.dataField === 'greenCertificates') {
      this.greenCertificationsEditorOptions = {
        dataSource: this.greenCertificationsDataSource,
        placeholder: 'Select building green certificates',
        acceptCustomValue: true,
        valueExpr: 'id',
        displayExpr: 'text',
        onCustomItemCreating: (args: any) => {
          let minId = 0;
          this.greenCertifications.forEach((val) => val.id < minId ? minId = val.id : null);

          const newVal = { id: minId - 1, text: args.text, type: vm.GreenCertification.Other };
          this.greenCertifications.push(newVal);
          this.greenCertificationsDataSource.insert(newVal);
          args.customItem = newVal;
        },
        onValueChanged: (v) => {
          e.setValue(v.value);
        }
      };

      this.greenCertificationsEditorOptions.value = this.selectedCertificates;
      e.editorName = 'dxTagBox';
      e.editorOptions = this.greenCertificationsEditorOptions;
    }

    if (e.parentType === 'dataRow' && e.dataField === 'portfolioId') {
      e.editorName = 'dxLookup';
      e.editorOptions = {
        dataSource: this.portfoliosDataSource,
        closeOnOutsideClick: true,
        showPopupTitle: false,
        showClearButton: true,
        placeholder: 'Select portfolio',
        valueExpr: 'id',
        displayExpr: 'name',
        value: e.value,
        onValueChanged: (selected: any) => {
          e.setValue(selected.value);
        },
      };
    }

    if (e.parentType === 'dataRow' && e.dataField === 'assetsTypeId') {
      e.editorName = 'dxSelectBox';
    }

    if (e.parentType === 'dataRow' && e.dataField === 'marketId') {
      e.editorName = 'dxLookup';

      const previousOnValueChanged = this.marketEditorOptions.onValueChanged;
      e.editorOptions.onValueChanged = async (event: {value: number, previousValue: number}) => {
        if (event.value === event.previousValue) {
          return;
        }

        e.setValue(event.value);
        if (previousOnValueChanged) {
          await previousOnValueChanged(event);
        }
      };
    }

    if (e.parentType === 'dataRow' && e.dataField === 'subMarketId') {
      this.subMarketEditorEl = e;

      e.editorName = 'dxLookup';

      const previousOnValueChanged = this.subMarketEditorOptions.onValueChanged;
      e.editorOptions.onValueChanged = async (event: {value: number, previousValue: number}) => {
        if (event.value === event.previousValue) {
          return;
        }

        e.setValue(event.value);
        if (previousOnValueChanged) {
          await previousOnValueChanged(event);
        }
      };
    }

    if (e.parentType === 'dataRow' && e.dataField) {
      const defaultOnValueChangedHandler = e.editorOptions.onValueChanged;

      e.editorOptions.onValueChanged = (event) => {
        this._mapDataFieldToModel(e.dataField, event.value);

        defaultOnValueChangedHandler(event);
      };
    }
  }

  private _mapDataFieldToModel(dataField: string, value: any): void {
    if (!dataField) {
      return;
    }

    const dataFieldFragments = dataField.split('.');

    let needleObject = this.currentBuilding;
    for (let i = 0, num = dataFieldFragments.length; i < num; i++) {
      const fragment = dataFieldFragments[i];
      if (!needleObject.hasOwnProperty(fragment)) {
        needleObject[fragment] = {};
      }

      if (i === dataFieldFragments.length - 1) {
        needleObject[fragment] = value;
        break;
      }

      needleObject = needleObject[fragment];
    }
  }

  editingStart(e) {
    this.marketId = null;
    this.subMarketsDataSource.load();

    const building = e.data as vm.IBuilding;

    this.currentBuilding = building;
    this.currentBuildingKey = e.key;

    this.currentAddress = building.address ? building.address : ({} as vm.IAddress);
    this.counties = this.allCounties.filter(x => x.stateCode === this.currentAddress.stateCode);

    this.markets = this.allMarkets;
    this.subMarkets = this.allSubMarkets.filter(x => x.parentMarketId === this.currentBuilding.marketId);

    this.marketEditorOptions.items = this.markets;
    this.subMarketEditorOptions.items = this.subMarkets;

    if (building && building.greenCertificates && building.greenCertificates.length > 0) {
      let minId = 0;
      const otherCertificates = [];
      const selectedCertificates = [];

      building.greenCertificates.forEach((cert) => {
        if (cert.certificationType === vm.GreenCertification.Other) {
          otherCertificates.push({id: minId - 1, type: cert.certificationType, text: cert.customValue});
          selectedCertificates.push(minId - 1);
          minId--;
          return;
        }

        const mappedCert = GREEN_CERTIFICATIONS.find(x => x.type === cert.certificationType);
        if (!mappedCert) {
          return;
        }
        selectedCertificates.push(mappedCert.id);
      });

      this.greenCertifications = [...GREEN_CERTIFICATIONS, ...otherCertificates];
      this.selectedCertificates = selectedCertificates;
    } else {
      this.selectedCertificates = [];
    }

    this.setEditorOptions();
  }

  customizeItem(item): void {
    if (item.dataField === 'loadingConfiguration') {
      console.log('customizeItem');
      item.visible = this.currentBuilding?.buildingType === vm.BuildingType.Industrial;
    }
  }

  initNewRow(e) {
    this.markets = this.allMarkets;
    this.subMarkets = [];

    this.marketEditorOptions.items = this.markets;
    this.subMarketEditorOptions.items = this.subMarkets;

    this.currentAddress = {} as vm.IAddress;
    this.currentBuilding = {} as vm.IBuilding;
    this.currentBuildingKey = 0;
  }

  onStateChange(e) {
    this.counties = this.allCounties.filter(x => x.stateCode === e.value);
    if (this.countyName) {
      this.countyName.option({
        items: this.counties,
        value: this.currentAddress.countyName,
      });
    }
  }

  updateAddressComboBoxes() {
    if (this.currentAddress) {
      if (this.currentAddress.stateCode) {
        this.onStateChange({value: this.currentAddress.stateCode});
      }
    }
  }

  addressFormInitialized() {
    this.updateAddressComboBoxes();
  }

  prepareToolbar($event) {
    if ($event.toolbarOptions) {
      $event.toolbarOptions.items = [
        {
          locateInMenu: 'auto',
          location: 'after',
          name: 'buildingImportTool',
          options: {
            icon: 'fa fa-upload',
            disabled: false,
            text: 'Import buildings',
            onClick: () => this.openImportBuildingsPopup(),
          },
          showText: 'inMenu',
          sortIndex: 21,
          widget: 'dxButton',
        },
        ...$event.toolbarOptions.items,
      ];
    }
  }

  openImportBuildingsPopup(): void {
    this._popupService.show(ImportComponent, {
      title: 'Import buildings',
      width: 750,
      maxHeight: '90%',
      showCloseButton: false,
      closeOnOutsideClick: false,
    });
  }

  setCellValue(this, newData, value, currentRowData) {
    // tslint:disable-next-line:no-non-null-assertion
    this.defaultSetCellValue!(newData, value, currentRowData);
  }

  isEditButtonVisible(e): boolean {
    return e && e.row && e.row.data && !e.row.data.deletedOn;
  }

  getLatAndLongByAddress(): void {
    if (!this.dxDataGridComponent || !this.currentBuilding || !this.currentBuilding.address) {
      return;
    }

    const gridInstance = this.dxDataGridComponent.instance;
    if (!gridInstance) {
      return;
    }

    let index = gridInstance.getRowIndexByKey(this.currentBuildingKey);
    if (index < 0) {
      index = 0;
    }

    let address = ``;

    const { addressLine1 } = this.currentBuilding.address;
    if (addressLine1) {
      address = `${address} ${addressLine1}`;
    }

    const { addressLine2 } = this.currentBuilding.address;
    if (addressLine2) {
      address = `${address}, ${addressLine2}`;
    }

    const { city } = this.currentBuilding.address;
    if (city) {
      address = `${address}, ${city}`;
    }

    const { countryCode } = this.currentBuilding.address;
    if (countryCode) {
      address = `${address}, ${countryCode}`;
    }

    const { zipCode } = this.currentBuilding.address;
    if (zipCode) {
      address = `${address}, ${zipCode}`;
    }

    const { stateCode } = this.currentBuilding.address;
    if (stateCode) {
      address = `${address}, ${stateCode}`;
    }

    if (!address) {
      return;
    }

    this._buildingService
      .getBuildingGeopointByAddress(address)
      .pipe(
        tap(geopoint => {
          if (!geopoint) {
            notify('Failed to get geopoint, please enter it manually', 'error');
            return;
          }

          gridInstance.cellValue(index, 'geopointLatitude', geopoint.latitude);
          gridInstance.cellValue(index, 'geopointLongitude', geopoint.longitude);
        }),
        take(1),
      )
      .subscribe();
  }
}
