import { ChangeDetectorRef, Component, EventEmitter, NgZone, OnDestroy, OnInit, ViewChild, ViewRef } from '@angular/core';
import { DxPopupComponent } from 'devextreme-angular';
import { Subscription } from 'rxjs';

import { PopupOptions } from '../../models/popup-options.model';

import { PopupRefService } from '../../services/popup-ref.service';

@Component({
  templateUrl: 'popup-container.component.html',
})
export class PopupContainerComponent implements OnInit, OnDestroy {
  private static readonly _defaultClassNames: Array<string> = ['popup'];

  @ViewChild(DxPopupComponent) dxPopupComponent: DxPopupComponent;

  readonly onShowing: EventEmitter<any>;
  readonly onHiding: EventEmitter<any>;
  readonly onShown: EventEmitter<any>;
  readonly onHidden: EventEmitter<any>;
  readonly onContentReady: EventEmitter<any>;

  readonly config: PopupOptions;

  isVisible: boolean;

  private readonly _ngZone: NgZone;
  private readonly _changeDetectorRef: ChangeDetectorRef;
  private readonly _popupRefService: PopupRefService;
  private readonly _subscriptions: Array<Subscription>;

  constructor(ngZone: NgZone, changeDetectorRef: ChangeDetectorRef, popupRefService: PopupRefService, config: PopupOptions) {
    this._ngZone = ngZone;
    this._changeDetectorRef = changeDetectorRef;
    this._popupRefService = popupRefService;
    this._subscriptions = [];

    this.onShowing = new EventEmitter<any>();
    this.onHiding = new EventEmitter<any>();
    this.onShown = new EventEmitter<any>();
    this.onHidden = new EventEmitter<any>();
    this.onContentReady = new EventEmitter<any>();

    this.config = config;
  }

  ngOnInit(): void {
    const hiddenSubscription = this.onHidden
      .subscribe(() => {
        this.config.popupService.hide(this.config.id);
        hiddenSubscription.unsubscribe();
      });

    // Fire sub-events
    this._firePopupRefEvent(this.onShowing, this._popupRefService.onShowing);
    this._firePopupRefEvent(this.onHiding, this._popupRefService.onHiding);
    this._firePopupRefEvent(this.onShown, this._popupRefService.onShown);
    this._firePopupRefEvent(this.onHidden, this._popupRefService.onHidden);
    this._firePopupRefEvent(this.onContentReady, this._popupRefService.onContentReady);

    this._ngZone.runOutsideAngular(() => {
      setTimeout(() => {
        if ((<ViewRef>this._changeDetectorRef).destroyed) {
          return;
        }

        this.isVisible = true;

        this._changeDetectorRef.markForCheck();
        this._changeDetectorRef.detectChanges();
      });
    });
  }

  ngOnDestroy(): void {
    if (this._subscriptions && this._subscriptions.length) {
      for (let i = 0, num = this._subscriptions.length; i < num; i++) {
        this._subscriptions[i].unsubscribe();
      }
    }
  }

  show() {
    this.isVisible = true;
  }

  hide() {
    this.isVisible = false;
  }

  repaint(): void {
    if (!this.dxPopupComponent || !this.dxPopupComponent.instance) {
      return;
    }

    this.dxPopupComponent.instance.repaint();
  }

  get getContainerClassNames(): string {
    if (this.config.containerClassNames) {
      return [...PopupContainerComponent._defaultClassNames, ...this.config.containerClassNames].join(' ');
    }

    return [...PopupContainerComponent._defaultClassNames].join(' ');
  }

  private _firePopupRefEvent(from: EventEmitter<any>, to: EventEmitter<any>): void {
    const subscription = from.subscribe((event) => to.emit(event));
    this._subscriptions.push(subscription);
  }
}
