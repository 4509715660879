<!-- Start: View options -->
<div class="dx-fieldset">
  <div class="dx-fieldset-header">
    View options
  </div>
  <!-- Start: Zoom field -->
  <div class="dx-field">
    <div class="dx-field-label">
      Zoom
    </div>
    <div class="dx-field-value">
      <dx-number-box appIgnoreScrollwheel name="zoom" id="zoom"
                     [showSpinButtons]="true" min="30" max="200" step="10"
                     [(ngModel)]="documentViewerOptions.zoom"
                     (ngModelChange)="handleDocumentViewerOptionsChange()">
      </dx-number-box>
    </div>
  </div>
  <!-- End: Zoom field -->
  <!-- Start: Page field -->
  <div class="dx-field">
    <div class="dx-field-label">
      Page
    </div>
    <div class="dx-field-value">
      <dx-number-box appIgnoreScrollwheel name="page" id="page"
                     [showSpinButtons]="true" min="1" [max]="documentViewerOptions.maxPage" step="1"
                     [(ngModel)]="documentViewerOptions.page"
                     (ngModelChange)="handleDocumentViewerOptionsChange()">
      </dx-number-box>
    </div>
  </div>
  <!-- End: Page field -->
</div>
<!-- End: View options -->
<!-- Start: Advanced options -->
<div class="dx-fieldset">
  <div class="dx-fieldset-header">
    Advanced options
  </div>
  <!-- Start: Preview mode -->
  <div class="dx-field">
    <div class="dx-field-value">
      <dx-check-box name="preview-mode" text="Preview mode"
                    [(ngModel)]="documentViewerOptions.previewMode"
                    (ngModelChange)="handleDocumentViewerOptionsChange()">
      </dx-check-box>
    </div>
  </div>
  <!-- End: Preview mode -->
  <!-- Start: Floating controls -->
  <div class="dx-field">
    <div class="dx-field-value">
      <dx-check-box name="preview-mode" text="Floating controls"
                    [(ngModel)]="documentViewerOptions.floatingControls"
                    (ngModelChange)="handleDocumentViewerOptionsChange()">
      </dx-check-box>
    </div>
  </div>
  <!-- End: Floating controls -->
</div>
<!-- End: Advanced options -->
<!-- Start: Task options -->
<div class="dx-fieldset"
     *ngIf="importTaskId">
  <div class="dx-fieldset-header">
    Task options
  </div>

  <div class="dx-field">
    <div class="dx-field">
      <div class="d-inline-flex flex-row align-items-center flex-nowrap mx-n2">
        <div class="px-2">
          <div class="text-center icon small">
            <i class="fa fa-tasks text-muted"></i>
          </div>
        </div>
        <div class="px-2 min-width-0">
          <p class="mb-0 small-text w-100 text-multiline-truncate">
            Task
          </p>
          <div class="d-block mb-0 w-100 text-multiline-truncate text-dark">
            Task #{{importTaskId}}
          </div>
        </div>
      </div>
    </div>
  </div>

  <ng-container *appVariable="importTaskStatus | async as importTaskStatus">
    <div class="dx-field">
      <div class="dx-field">
        <div class="d-inline-flex flex-row align-items-center flex-nowrap mx-n2">
          <div class="px-2">
            <div class="text-center icon small">
              <i class="fa fa-hourglass-start text-muted"></i>
            </div>
          </div>
          <div class="px-2 min-width-0">
            <p class="mb-0 small-text w-100 text-multiline-truncate">
              Task status
            </p>
            <div class="d-block mb-0 w-100 text-multiline-truncate text-dark">
              <span class="badge badge-pill"
                    [class.badge-secondary]="importTaskStatus === TaskStatus.NotStarted"
                    [class.badge-primary]="importTaskStatus === TaskStatus.Running"
                    [class.badge-success]="importTaskStatus === TaskStatus.Success"
                    [class.badge-warning]="importTaskStatus === TaskStatus.WithErrors"
                    [class.badge-danger]="importTaskStatus === TaskStatus.Failed">
                {{getImportTaskStatusName(importTaskStatus)}}
              </span>
            </div>
          </div>
        </div>
      </div>
      <ng-container *ngIf="importTaskStatus === TaskStatus.NotStarted">
        <p class="small mt-3 mb-0 text-muted font-weight-bold">
          * The task will start within 5 minutes
        </p>
      </ng-container>
    </div>
  </ng-container>
</div>
<!-- End: Task options -->
<!-- Start: Lease options -->
<div class="dx-fieldset">
  <div class="dx-fieldset-header">
    Lease options
  </div>

  <div class="dx-field">
    <div class="d-inline-flex flex-row align-items-center flex-nowrap mx-n2">
      <div class="px-2">
        <div class="text-center icon small">
          <i class="fa fa-info text-muted"></i>
        </div>
      </div>
      <div class="px-2 min-width-0">
        <p class="mb-0 small-text w-100 text-multiline-truncate">
          Lease type
        </p>
        <a role="button" class="d-block mb-0 w-100 text-multiline-truncate text-dark hover-trigger"
           (click)="openOptionsEditDialog()">
          <ng-container [ngSwitch]="lease?.leaseType">
            <ng-container *ngSwitchCase="LeaseTypes.None">
              None<i class="fa fa-pencil mx-2 text-muted show-on-hover"></i>
            </ng-container>
            <ng-container *ngSwitchCase="LeaseTypes.Internal">
              Internal<i class="fa fa-pencil mx-2 text-muted show-on-hover"></i>
            </ng-container>
            <ng-container *ngSwitchCase="LeaseTypes.ThirdPartyLease">
              3rd Party<i class="fa fa-pencil mx-2 text-muted show-on-hover"></i>
            </ng-container>
          </ng-container>
        </a>
      </div>
    </div>
  </div>

  <div class="dx-field"
       *ngIf="lease?.amendmentNumber as amendmentNumber">
    <div class="d-inline-flex flex-row align-items-center flex-nowrap mx-n2">
      <div class="px-2">
        <div class="text-center icon small">
          <i class="fa fa-code-fork text-muted"></i>
        </div>
      </div>
      <div class="px-2 min-width-0">
        <p class="mb-0 small-text w-100 text-multiline-truncate">
          Abstract info
        </p>
        <a role="button" class="d-block mb-0 w-100 text-multiline-truncate text-dark hover-trigger"
           (click)="openOptionsEditDialog()">
          Amendment {{amendmentNumber}}<i class="fa fa-pencil mx-2 text-muted show-on-hover"></i>
        </a>
        <p class="d-block small mb-0 w-100 text-multiline-truncate text-dark"
           *ngIf="lease?.abstractLeaseId as abstractLeaseId">
          Abstract #{{abstractLeaseId}}
        </p>
      </div>
    </div>
  </div>

  <div class="dx-field">
    <div class="d-inline-flex flex-row align-items-center flex-nowrap mx-n2">
      <div class="px-2">
        <div class="text-center icon small">
          <i class="fa fa-usd text-muted"></i>
        </div>
      </div>
      <div class="px-2 min-width-0">
        <p class="mb-0 small-text w-100 text-multiline-truncate">
          Colábo metrics
        </p>
        <a role="button" class="d-block mb-0 w-100 text-multiline-truncate text-dark hover-trigger"
           (click)="openOptionsEditDialog()">
          <ng-container [ngSwitch]="lease?.leaseMetrics">
            <ng-container *ngSwitchCase="LeaseMetrics.PsfPerYear">
              PSF/YR<i class="fa fa-pencil mx-2 text-muted show-on-hover"></i>
            </ng-container>
            <ng-container *ngSwitchCase="LeaseMetrics.PsfPerMonth">
              PSF/MO<i class="fa fa-pencil mx-2 text-muted show-on-hover"></i>
            </ng-container>
          </ng-container>
        </a>
      </div>
    </div>
  </div>
</div>
<!-- End: Lease options -->
<!-- Start: Landlord -->
<div *ngIf="lease?.landlordCompany" class="dx-fieldset">
  <div class="dx-fieldset-header">
    Landlord company
  </div>
  <div class="dx-field">
    <ng-container *ngIf="lease?.landlordCompany as landlordCompany">
      <div class="d-inline-flex flex-row align-items-center flex-nowrap mx-n2">
        <div class="px-2">
          <ng-container *ngIf="landlordCompany.logo?.url as companyThumbnail; else noCompanyThumbnailTemplate">
            <img width="40" height="40" style="object-fit: cover"
                 [src]="companyThumbnail"
                 [alt]="landlordCompany.name" />
          </ng-container>
          <ng-template #noCompanyThumbnailTemplate>
            <div class="text-center icon">
              <i class="fa fa-picture-o text-muted"></i>
            </div>
          </ng-template>
        </div>
        <div class="px-2 min-width-0">
          <a role="button" class="d-block mb-0 w-100 text-multiline-truncate text-dark hover-trigger"
             [title]="landlordCompany.name"
             (click)="openOptionsEditDialog()">
            {{landlordCompany.name}}<i class="fa fa-pencil mx-2 text-muted show-on-hover"></i>
          </a>
        </div>
      </div>
    </ng-container>
  </div>
</div>
<!-- End: Landlord -->
<!-- Start: Tenant -->
<div class="dx-fieldset">
  <div class="dx-fieldset-header">
    Tenant company
  </div>
  <div class="dx-field">
    <ng-container *ngIf="lease?.tenantCompany as tenantCompany">
      <div class="d-inline-flex flex-row align-items-center flex-nowrap mx-n2">
        <div class="px-2">
          <ng-container *ngIf="tenantCompany.logo?.url as companyThumbnail; else noCompanyThumbnailTemplate">
            <img width="40" height="40" style="object-fit: cover"
                 [src]="companyThumbnail"
                 [alt]="tenantCompany.name" />
          </ng-container>
          <ng-template #noCompanyThumbnailTemplate>
            <div class="text-center icon">
              <i class="fa fa-picture-o text-muted"></i>
            </div>
          </ng-template>
        </div>
        <div class="px-2 min-width-0">
          <a role="button" class="d-block mb-0 w-100 text-multiline-truncate text-dark hover-trigger"
             [title]="tenantCompany.name"
             (click)="openOptionsEditDialog()">
            {{tenantCompany.name}}<i class="fa fa-pencil mx-2 text-muted show-on-hover"></i>
          </a>
        </div>
      </div>
    </ng-container>
  </div>
</div>
<!-- End: Tenant -->
<!-- Start: Building -->
<div class="dx-fieldset">
  <div class="dx-fieldset-header">
    Building
  </div>
  <div class="dx-field">
    <ng-container *ngIf="lease?.building as buildingProfile">
      <div class="d-inline-flex flex-row align-items-center flex-nowrap mx-n2">
        <div class="px-2">
          <ng-container *ngIf="tools.buildingPictureThumbnail(buildingProfile) as buildingThumbnail; else noBuildingThumbnailTemplate">
            <img width="40" height="40" style="object-fit: cover"
                 [src]="buildingThumbnail"
                 [alt]="buildingProfile.name" />
          </ng-container>
          <ng-template #noBuildingThumbnailTemplate>
            <div class="text-center icon">
              <i class="fa fa-building-o text-muted"></i>
            </div>
          </ng-template>
        </div>
        <div class="px-2 min-width-0">
          <a role="button" class="d-block mb-0 w-100 text-multiline-truncate text-dark hover-trigger"
             [title]="buildingProfile.name"
             (click)="openOptionsEditDialog()">
            {{buildingProfile.name}}<i class="fa fa-pencil mx-2 text-muted show-on-hover"></i>
          </a>
          <p class="mb-0 small w-100 text-multiline-truncate"
             *ngIf="lease?.buildingUnit as buildingUnitProfile"
             [title]="buildingUnitProfile.name">
            {{buildingUnitProfile.name}}
          </p>
        </div>
      </div>
    </ng-container>
  </div>
</div>
<!-- End: Building -->
