<h2 class="content-block">
  Global Features
</h2>

<dx-data-grid class="dx-card wide-card"
              [dataSource]="dataSource"
              [columnAutoWidth]="true"
              [columnHidingEnabled]="true"
              #dxDataGrid>
  <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[5, 15, 30]" [showInfo]="true"></dxo-pager>
  <dxo-paging [pageSize]="15"></dxo-paging>
  <dxo-filter-row [visible]="true"></dxo-filter-row>
  <dxo-filter-panel [visible]="true"></dxo-filter-panel>
  <dxo-filter-builder></dxo-filter-builder>
  <dxo-filter-builder-popup> </dxo-filter-builder-popup>
  <dxo-search-panel [visible]="true"></dxo-search-panel>
  <dxo-group-panel [visible]="true"></dxo-group-panel>

  <dxi-column dataField="isColaboFeatureEnabled" dataType="boolean" caption="Colabo Feature">
  </dxi-column>

  <dxi-column dataField="isProjectScheduleFeatureEnabled" dataType="boolean" caption="Project Schedule Feature">
  </dxi-column>

  <dxi-column dataField="isFloorPlanFeatureEnabled" dataType="boolean" caption="Floor plan Feature">
  </dxi-column>

  <dxi-column dataField="isPlanProEnabled" dataType="boolean" caption="Plan Pro Feature">
  </dxi-column>

  <dxi-column type="buttons" caption="Actions">
    <dxi-button text="Edit"
                [onClick]="edit">
    </dxi-button>
  </dxi-column>
</dx-data-grid>
