import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DxDataGridComponent } from 'devextreme-angular';
import * as AspNetData from 'devextreme-aspnet-data-nojquery';
import CustomStore from 'devextreme/data/custom_store';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { Mapping } from '../../models/mapping.model';

import { ImportService } from '../../services/import.service';

import { Tools } from '../../../../shared/tools';
import { SelectItem } from '../../../../shared/models/select-item.model';

import { RowCell } from '../../models/row-cell.model';

import * as models from '../../../../shared/models/generated';

import { environment } from '../../../../../environments/environment';

@Component({
  templateUrl: 'import-task-list.component.html',
  styleUrls: ['import-task-list.component.scss'],
})
export class ImportTaskListComponent implements OnInit, OnDestroy {
  private static readonly _importTaskListUrl = `${environment.adminUrl}/import`;

  @ViewChild('dxDataGrid', {static: false}) dxDataGridComponent: DxDataGridComponent;

  dataSource: CustomStore;

  TaskType: typeof models.ImportTaskType = models.ImportTaskType;
  TaskStatus: typeof models.ImportTaskStatus = models.ImportTaskStatus;

  taskTypes: Array<SelectItem>;
  taskStatuses: Array<SelectItem>;

  private readonly _importService: ImportService;
  private readonly _destroy: Subject<void>;

  constructor(importService: ImportService) {
    this._importService = importService;
    this._destroy = new Subject<void>();

    this.isRescheduleButtonVisible = this.isRescheduleButtonVisible.bind(this);
    this.reschedule = this.reschedule.bind(this);
  }

  ngOnInit(): void {
    this.dataSource = AspNetData.createStore({
      loadUrl: ImportTaskListComponent._importTaskListUrl,
    });

    this.taskTypes = Tools.EnumToArray(models.ImportTaskType).map(x => ({...x, text: this.humanizeText(x.text)}));
    this.taskStatuses = Tools.EnumToArray(models.ImportTaskStatus).map(x => ({...x, text: this.humanizeText(x.text)}));
  }

  ngOnDestroy(): void {
    this._destroy.next();
    this._destroy.complete();
  }

  getRecordCells(record: Record<string, any>): { header: Array<any>, body: Array<any> } {
    const header = Object.keys(record).map(x => this.humanizeText(x));
    const body = Object.values(record);

    return {header, body};
  }

  getRows(mapping: Mapping, record: {rawModel: Array<string>, model: {[key: string]: any}}): Array<RowCell> {
    if (!record) {
      return null;
    }

    if (record.rawModel) {
      return this._getRawModelRows(mapping, record.rawModel);
    }

    if (record.model) {
      return this._getModelRows(mapping, record.model);
    }

    return null;
  }

  hasRowError(record: {errors: Array<models.IErrorImportModel>}, fieldIndex: number, fieldName: string): boolean {
    if (!fieldName) {
      return false;
    }

    if (typeof fieldIndex === 'number') {
      const indexedErrors = record.errors.filter(x => typeof x.fieldIndex === 'number');
      return 0 <= indexedErrors.findIndex(x => x.fieldIndex === fieldIndex);
    }

    const errorIndex = record.errors
      .filter(x => x.fieldName)
      .findIndex(x => x.fieldName.toLowerCase() === fieldName.toLowerCase());

    return 0 <= errorIndex;
  }

  getRowError(record: {errors: Array<models.IErrorImportModel>}, fieldIndex: number, fieldName: string): string {
    if (!record || !record.errors || !record.errors.length || !fieldName) {
      return null;
    }

    if (typeof fieldIndex === 'number') {
      const indexedErrors = record.errors.filter(x => typeof x.fieldIndex === 'number');

      const indexOfIndexedError = indexedErrors.findIndex(x => x.fieldIndex === fieldIndex);
      if (0 <= indexOfIndexedError) {
        return indexedErrors[indexOfIndexedError].message;
      }
    }

    const errors = record.errors.filter(x => x.fieldName);

    const errorIndex = errors.findIndex(x => x.fieldName.toLowerCase() === fieldName.toLowerCase());
    if (0 <= errorIndex) {
      return errors[errorIndex].message;
    }

    return null;
  }

  getGlobalErrors(record: {errors: Array<models.IErrorImportModel>}): Array<string> {
    if (!record || !record.errors || !record.errors.length) {
      return null;
    }

    const errors = record.errors
      .filter(x => !x.fieldName)
      .map(x => x.message);

    if (errors.length === 0) {
      return null;
    }

    return errors;
  }

  private _getRawModelRows(mapping: Mapping, rawModel: Array<string>): Array<RowCell> {
    const mappingKeys = Object.keys(mapping);
    const mappingValues = Object.values(mapping);

    const rowCells = [];
    for (let i = 0, num = mappingValues.length; i < num; i++) {
      const fieldIndex = mappingValues[i];
      const fieldName = mappingKeys[i];

      const rowCell = {
        fieldIndex: fieldIndex,
        fieldName: fieldName,
        fieldValue: rawModel[fieldIndex],
      };

      if (rowCell.fieldValue === undefined) {
        rowCell.fieldValue = '–';
      }

      rowCells.push(rowCell);
    }

    return rowCells;
  }

  private _getModelRows(mapping: Mapping, model: {[key: string]: any}): Array<RowCell> {
    const mappingKeys = Object.keys(mapping);
    const mappingValues = Object.values(mapping);

    const rowCells = [];
    for (let i = 0, num = mappingValues.length; i < num; i++) {
      const fieldIndex = mappingValues[i];
      const fieldName = mappingKeys[i];

      const rowCell = {
        fieldIndex: fieldIndex,
        fieldName: fieldName,
        fieldValue: model[fieldName],
      };

      if (rowCell.fieldValue === undefined) {
        rowCell.fieldValue = '–';
      }

      rowCells.push(rowCell);
    }

    return rowCells;
  }

  humanizeText(str: string): string {
    const tokens = str.replace(/([A-Z])/g, ' $1');
    return tokens.charAt(0).toUpperCase() + tokens.slice(1);
  }

  getImportedRecordsCount({data}): number {
    if (!data || !data.taskLogs || !data.taskLogs.length) {
      return 0;
    }

    let importedRecordsCount = 0;
    for (let i = 0, num = data.taskLogs.length; i < num; i++) {
      const taskLog = data.taskLogs[i];
      if (!taskLog || !taskLog.message || !taskLog.message.importedRecords || !taskLog.message.importedRecords.length) {
        continue;
      }

      importedRecordsCount += taskLog.message.importedRecords.length;
    }

    return importedRecordsCount;
  }

  getFailedRecordsCount({data}): number {
    if (!data || !data.taskLogs || !data.taskLogs.length) {
      return 0;
    }

    let failedRecordsCount = 0;
    for (let i = 0, num = data.taskLogs.length; i < num; i++) {
      const taskLog = data.taskLogs[i];
      if (!taskLog || !taskLog.message || !taskLog.message.failedRecords || !taskLog.message.failedRecords.length) {
        continue;
      }

      failedRecordsCount += taskLog.message.failedRecords.length;
    }

    return failedRecordsCount;
  }

  isRescheduleButtonVisible(event): boolean {
    if (!event || !event.row || !event.row.data) {
      return false;
    }

    return event.row.data.taskStatus !== models.ImportTaskStatus.NotStarted;
  }

  reschedule(event): void {
    if (!event || !event.row || !event.row.data) {
      return;
    }

    this._importService
      .reschedule(event.row.data.id)
      .pipe(
        tap(() => {
          if (this.dxDataGridComponent && this.dxDataGridComponent.instance) {
            const dataSource = this.dxDataGridComponent.instance.getDataSource();
            dataSource.reload();
          }
        }),
        takeUntil(this._destroy),
      )
      .subscribe();
  }
}
