<dx-scroll-view width="100%" height="100%">
  <form name="form" novalidate
        (ngSubmit)="submit(form)"
        #form="ngForm">
    <!-- Start: Invitation kind select -->
    <div class="dx-fieldset">
      <div class="dx-field">
        <div class="dx-field-label">
          Invitation kind
        </div>
        <div class="dx-field-value">
          <dx-lookup name="userInvitationKind" required
                     [disabled]="isRequestInjected"
                     fieldTemplate="field" itemTemplate="listItem" valueExpr="id"
                     [showPopupTitle]="false" [showCancelButton]="false" [closeOnOutsideClick]="true"
                     [searchEnabled]="false" [cleanSearchOnOpening]="true" [dataSource]="userInvitationKindDataSource"
                     [isValid]="form.submitted ? userInvitationKindField.valid : true"
                     [(ngModel)]="userInvitationKind"
                     (ngModelChange)="handleKindChange($event)"
                     #userInvitationKindField="ngModel">
            <ng-container *dxTemplate="let kind of 'field'">
              <ng-container *ngIf="kind; else noKind">
                <div class="lookup-field d-flex flex-row align-items-center flex-nowrap mx-n1">
                  <div class="lookup-field-summary px-1 min-width-0">
                    <p class="mb-0 w-100 text-truncate">
                      {{kind.text}}
                    </p>
                  </div>
                </div>
              </ng-container>
              <ng-template #noKind>
                <span class="text-muted">
                  Select invitation kind
                </span>
              </ng-template>
            </ng-container>

            <ng-container *dxTemplate="let kind of 'listItem'">
              <div class="lookup-field d-flex flex-row align-items-center flex-nowrap mx-n1">
                <div class="lookup-field-summary px-1">
                  <div class="lookup-field-summary px-1 min-width-0">
                    <p class="mb-0 w-100 text-truncate">
                      {{kind.text}}
                    </p>
                  </div>
                </div>
              </div>
            </ng-container>
          </dx-lookup>
          <div class="my-1 invalid-feedback d-block"
               *ngIf="form.submitted && userInvitationKindField.hasError('required')">
            Invitation kind is required
          </div>
        </div>
      </div>
    </div>
    <!-- Start: Invitation kind select -->
    <ng-container *ngIf="userInvitationKind">
      <!-- Start: Lease select -->
      <div class="dx-fieldset"
           *ngIf="userInvitationKind === UserInvitationRequestKind.Lease">
        <div class="dx-field">
          <div class="dx-field-label">
            Lease
          </div>
          <div class="dx-field-value">
            <dx-lookup name="leaseId"
                       noDataText="No leases found matching your criteria"
                       fieldTemplate="field" itemTemplate="listItem" valueExpr="id"
                       [showPopupTitle]="false" [showCancelButton]="false" [closeOnOutsideClick]="true"
                       [searchEnabled]="false" [cleanSearchOnOpening]="true" [dataSource]="leaseDataSource"
                       (onSelectionChanged)="handleLeaseChange($event)">
              <ng-container *dxTemplate="let lease of 'field'">
                <ng-container *ngIf="lease; else noLease">
                  <div class="lookup-field d-flex flex-row align-items-center flex-nowrap mx-n1">
                    <div class="lookup-field-summary px-1 min-width-0">
                      <p class="mb-0 w-100 text-truncate">
                        {{Tools.shortLeaseNameExpression(lease)}}
                      </p>
                    </div>
                  </div>
                </ng-container>
                <ng-template #noLease>
                  <span class="text-muted">
                    Select lease
                  </span>
                </ng-template>
              </ng-container>

              <ng-container *dxTemplate="let lease of 'listItem'">
                <div class="lookup-field d-flex flex-row align-items-center flex-nowrap mx-n1">
                  <div class="lookup-field-summary px-1">
                    <div class="lookup-field-summary px-1 min-width-0">
                      <p class="mb-0 w-100 text-truncate">
                        {{Tools.shortLeaseNameExpression(lease)}}
                      </p>
                    </div>
                  </div>
                </div>
              </ng-container>
            </dx-lookup>
          </div>
          <div class="mt-2"
               *ngIf="userInvitation.leaseIds?.length">
            Leases selected:
            <span *ngFor="let leaseId of userInvitation.leaseIds; let i = index">
              id {{leaseId}} <a href="#" (click)="$event.preventDefault(); handleLeaseRemoveClick(leaseId)">x</a>
              <ng-container *ngIf="i !== userInvitation.leaseIds.length - 1">,</ng-container>
            </span>
          </div>
        </div>
      </div>
      <!-- End: Lease select -->
      <!-- Start: Building select -->
      <div class="dx-fieldset"
           *ngIf="userInvitationKind === UserInvitationRequestKind.Building">
        <div class="dx-field">
          <div class="dx-field-label">
            Building
          </div>
          <div class="dx-field-value">
            <dx-lookup name="buildingId"
                       noDataText="No buildings found matching your criteria"
                       fieldTemplate="field" itemTemplate="listItem" valueExpr="id"
                       [showPopupTitle]="false" [showCancelButton]="false" [closeOnOutsideClick]="true"
                       [searchEnabled]="false" [cleanSearchOnOpening]="true" [dataSource]="buildingsDataSource"
                       (onSelectionChanged)="handleBuildingChange($event)">
              <ng-container *dxTemplate="let building of 'field'">
                <ng-container *ngIf="building; else noBuilding">
                  <div class="lookup-field d-flex flex-row align-items-center flex-nowrap mx-n1">
                    <div class="lookup-field-summary px-1 min-width-0">
                      <p class="mb-0 w-100 text-truncate">
                        {{building.name}}
                      </p>
                    </div>
                  </div>
                </ng-container>
                <ng-template #noBuilding>
                  <span class="text-muted">
                    Select building
                  </span>
                </ng-template>
              </ng-container>

              <ng-container *dxTemplate="let building of 'listItem'">
                <div class="lookup-field d-flex flex-row align-items-center flex-nowrap mx-n1">
                  <div class="lookup-field-summary px-1">
                    <div class="lookup-field-summary px-1 min-width-0">
                      <p class="mb-0 w-100 text-truncate">
                        {{building.name}}
                      </p>
                    </div>
                  </div>
                </div>
              </ng-container>
            </dx-lookup>
          </div>
          <div class="mt-2"
               *ngIf="userInvitation.buildingIds?.length">
            Buildings selected:
            <span *ngFor="let buildingId of userInvitation.buildingIds; let i = index">
              id {{buildingId}} <a href="#" (click)="$event.preventDefault(); handleBuildingRemoveClick(buildingId)">x</a>
              <ng-container *ngIf="i !== userInvitation.buildingIds.length - 1">,</ng-container>
            </span>
          </div>
        </div>
      </div>
      <!-- End: Building select -->
      <!-- Start: Portfolio select -->
      <div class="dx-fieldset"
           *ngIf="userInvitationKind === UserInvitationRequestKind.Portfolio">
        <div class="dx-field">
          <div class="dx-field-label">
            Portfolio
          </div>
          <div class="dx-field-value">
            <dx-lookup name="portfolioId"
                       noDataText="No portfolios found matching your criteria"
                       fieldTemplate="field" itemTemplate="listItem" valueExpr="id"
                       [showPopupTitle]="false" [showCancelButton]="false" [closeOnOutsideClick]="true"
                       [searchEnabled]="false" [cleanSearchOnOpening]="true" [dataSource]="portfoliosDataSource"
                       (onSelectionChanged)="handlePortfolioChange($event)">
              <ng-container *dxTemplate="let portfolio of 'field'">
                <ng-container *ngIf="portfolio; else noPortfolio">
                  <div class="lookup-field d-flex flex-row align-items-center flex-nowrap mx-n1">
                    <div class="lookup-field-summary px-1 min-width-0">
                      <p class="mb-0 w-100 text-truncate">
                        {{portfolio.name}}
                      </p>
                    </div>
                  </div>
                </ng-container>
                <ng-template #noPortfolio>
                  <span class="text-muted">
                    Select portfolio
                  </span>
                </ng-template>
              </ng-container>

              <ng-container *dxTemplate="let portfolio of 'listItem'">
                <div class="lookup-field d-flex flex-row align-items-center flex-nowrap mx-n1">
                  <div class="lookup-field-summary px-1">
                    <div class="lookup-field-summary px-1 min-width-0">
                      <p class="mb-0 w-100 text-truncate">
                        {{portfolio.name}}
                      </p>
                    </div>
                  </div>
                </div>
              </ng-container>
            </dx-lookup>
          </div>
          <div class="mt-2"
               *ngIf="userInvitation.portfolioIds?.length">
            Portfolios selected:
            <span *ngFor="let portfolioId of userInvitation.portfolioIds; let i = index">
              id {{portfolioId}} <a href="#" (click)="$event.preventDefault(); handlePortfolioRemoveClick(portfolioId)">x</a>
              <ng-container *ngIf="i !== userInvitation.portfolioIds.length - 1">,</ng-container>
            </span>
          </div>
        </div>
      </div>
      <!-- End: Portfolio select -->
      <!-- Start: User select -->
      <div class="dx-fieldset">
        <div class="dx-field">
          <div class="dx-field-label">
            User
          </div>
          <div class="dx-field-value">
            <dx-lookup name="userId" required
                       noDataText="No users found matching your criteria"
                       fieldTemplate="field" itemTemplate="listItem" valueExpr="id"
                       [showPopupTitle]="false" [showCancelButton]="false" [closeOnOutsideClick]="true"
                       [cleanSearchOnOpening]="true" [dataSource]="userDataSource"
                       [isValid]="form.submitted ? userId.valid : true" [searchExpr]="searchableUserFields"
                       [(ngModel)]="userInvitation.userId" #userId="ngModel">
              <ng-container *dxTemplate="let profile of 'field'">
                <ng-container *ngIf="profile; else noUser">
                  <div class="lookup-field d-flex flex-row align-items-center flex-nowrap mx-n1">
                    <div class="lookup-field-img px-1">
                      <img class="rounded-circle" width="20" height="20"
                           [src]="profile.avatar && profile.avatar.url ? profile.avatar.url : 'assets/img/avatar.png'"
                           [alt]="profile.firstName + ' ' + profile.lastName" />
                    </div>
                    <div class="lookup-field-summary px-1 min-width-0">
                      <p class="mb-0 w-100 text-truncate">
                        {{profile.firstName}}
                        {{profile.lastName}}
                      </p>
                    </div>
                  </div>
                </ng-container>
                <ng-template #noUser>
                  <span class="text-muted">
                    Select user
                  </span>
                </ng-template>
              </ng-container>

              <ng-container *dxTemplate="let profile of 'listItem'">
                <div class="lookup-field d-flex flex-row align-items-center flex-nowrap mx-n1">
                  <div class="lookup-field-img px-1">
                    <img class="rounded-circle" width="40" height="40"
                         [src]="profile.avatar && profile.avatar.url ? profile.avatar.url : 'assets/img/avatar.png'"
                         [alt]="profile.firstName + ' ' + profile.lastName" />
                  </div>
                  <div class="lookup-field-summary px-1">
                    <div class="lookup-field-summary px-1 min-width-0">
                      <p class="mb-0 w-100 text-truncate">
                        {{profile.firstName}}
                        {{profile.lastName}}
                      </p>
                      <p class="mb-0 small w-100 text-truncate"
                         *ngIf="profile.company && profile.company.name">
                        {{profile.company.name}}
                      </p>
                    </div>
                  </div>
                </div>
              </ng-container>
            </dx-lookup>
            <div class="my-1 invalid-feedback d-block"
                 *ngIf="form.submitted && userId.hasError('required')">
              User is required
            </div>
          </div>
        </div>
      </div>
      <!-- End: User select -->
      <!-- Start: Role select -->
      <div class="dx-fieldset">
        <div class="dx-field">
          <div class="dx-field-label">
            Role
          </div>
          <div class="dx-field-value">
            <dx-lookup name="leaseUserRole" required
                       noDataText="No roles found matching your criteria"
                       fieldTemplate="field" itemTemplate="listItem" valueExpr="id"
                       [showPopupTitle]="false" [showCancelButton]="false" [closeOnOutsideClick]="true"
                       [searchEnabled]="false" [cleanSearchOnOpening]="true" [dataSource]="leaseUserRolesDataSource"
                       [isValid]="form.submitted ? leaseUserRole.valid : true"
                       [(ngModel)]="userInvitation.leaseUserRole"
                       (ngModelChange)="handleRoleChange($event)"
                       #leaseUserRole="ngModel">
              <ng-container *dxTemplate="let role of 'field'">
                <ng-container *ngIf="role; else noRole">
                  <div class="lookup-field d-flex flex-row align-items-center flex-nowrap mx-n1">
                    <div class="lookup-field-summary px-1 min-width-0">
                      <p class="mb-0 w-100 text-truncate">
                        {{role.text}}
                      </p>
                    </div>
                  </div>
                </ng-container>
                <ng-template #noRole>
                  <span class="text-muted">
                    Select role
                  </span>
                </ng-template>
              </ng-container>

              <ng-container *dxTemplate="let role of 'listItem'">
                <div class="lookup-field d-flex flex-row align-items-center flex-nowrap mx-n1">
                  <div class="lookup-field-summary px-1">
                    <div class="lookup-field-summary px-1 min-width-0">
                      <p class="mb-0 w-100 text-truncate">
                        {{role.text}}
                      </p>
                    </div>
                  </div>
                </div>
              </ng-container>
            </dx-lookup>
            <div class="my-1 invalid-feedback d-block"
                 *ngIf="form.submitted && leaseUserRole.hasError('required')">
              Role is required
            </div>
          </div>
        </div>
      </div>
      <!-- Start: Role select -->
      <!-- Start: Invited by select -->
      <div class="dx-fieldset">
        <div class="dx-field">
          <div class="dx-field-label">
            Invited by
          </div>
          <div class="dx-field-value">
            <dx-lookup name="invitedByUserId" required
                       noDataText="No users found matching your criteria"
                       fieldTemplate="field" itemTemplate="listItem" valueExpr="id"
                       [showPopupTitle]="false" [showCancelButton]="false" [closeOnOutsideClick]="true"
                       [cleanSearchOnOpening]="true" [dataSource]="userDataSource"
                       [isValid]="form.submitted ? invitedByUserId.valid : true" [searchExpr]="searchableUserFields"
                       [(ngModel)]="userInvitation.invitedByUserId" #invitedByUserId="ngModel">
              <ng-container *dxTemplate="let profile of 'field'">
                <ng-container *ngIf="profile; else noUser">
                  <div class="lookup-field d-flex flex-row align-items-center flex-nowrap mx-n1">
                    <div class="lookup-field-img px-1">
                      <img class="rounded-circle" width="20" height="20"
                           [src]="profile.avatar && profile.avatar.url ? profile.avatar.url : 'assets/img/avatar.png'"
                           [alt]="profile.firstName + ' ' + profile.lastName" />
                    </div>
                    <div class="lookup-field-summary px-1 min-width-0">
                      <p class="mb-0 w-100 text-truncate">
                        {{profile.firstName}}
                        {{profile.lastName}}
                      </p>
                    </div>
                  </div>
                </ng-container>
                <ng-template #noUser>
                  <span class="text-muted">
                    Select user
                  </span>
                </ng-template>
              </ng-container>

              <ng-container *dxTemplate="let profile of 'listItem'">
                <div class="lookup-field d-flex flex-row align-items-center flex-nowrap mx-n1">
                  <div class="lookup-field-img px-1">
                    <img class="rounded-circle" width="40" height="40"
                         [src]="profile.avatar && profile.avatar.url ? profile.avatar.url : 'assets/img/avatar.png'"
                         [alt]="profile.firstName + ' ' + profile.lastName" />
                  </div>
                  <div class="lookup-field-summary px-1">
                    <div class="lookup-field-summary px-1 min-width-0">
                      <p class="mb-0 w-100 text-truncate">
                        {{profile.firstName}}
                        {{profile.lastName}}
                      </p>
                      <p class="mb-0 small w-100 text-truncate"
                         *ngIf="profile.company && profile.company.name">
                        {{profile.company.name}}
                      </p>
                    </div>
                  </div>
                </div>
              </ng-container>
            </dx-lookup>
            <div class="my-1 invalid-feedback d-block"
                 *ngIf="form.submitted && invitedByUserId.hasError('required')">
              User is required
            </div>
          </div>
        </div>
      </div>
      <!-- End: Invited by select -->
      <!-- Start: Related to select -->
      <div class="dx-fieldset">
        <div class="dx-field">
          <div class="dx-field-label">
            To work with
          </div>
          <div class="dx-field-value">
            <dx-lookup name="relatedToCompanyId" required
                       noDataText="No companies found matching your criteria"
                       fieldTemplate="field" itemTemplate="listItem" valueExpr="id"
                       [showPopupTitle]="false" [showCancelButton]="false" [closeOnOutsideClick]="true"
                       [searchEnabled]="false" [cleanSearchOnOpening]="true" [dataSource]="companyDataSource"
                       [isValid]="form.submitted ? relatedToCompanyId.valid : true"
                       [(ngModel)]="userInvitation.relatedToCompanyId" #relatedToCompanyId="ngModel">
              <ng-container *dxTemplate="let company of 'field'">
                <ng-container *ngIf="company; else noCompany">
                  <div class="lookup-field d-flex flex-row align-items-center flex-nowrap mx-n1">
                    <div class="lookup-field-img px-1">
                      <img width="20" height="20" style="object-fit: cover"
                           [src]="company.logo && company.logo.url ? company.logo.url : 'assets/img/nofoto.png'"
                           [alt]="company.name" />
                    </div>
                    <div class="lookup-field-summary px-1 min-width-0">
                      <p class="mb-0 w-100 text-truncate">
                        {{company.name}}
                      </p>
                    </div>
                  </div>
                </ng-container>
                <ng-template #noCompany>
                  <span class="text-muted">
                    Select company
                  </span>
                </ng-template>
              </ng-container>

              <ng-container *dxTemplate="let company of 'listItem'">
                <div class="lookup-field d-flex flex-row align-items-center flex-nowrap mx-n1">
                  <div class="lookup-field-img px-1">
                    <img width="40" height="40" style="object-fit: cover"
                         [src]="company.logo && company.logo.url ? company.logo.url : 'assets/img/nofoto.png'"
                         [alt]="company.name" />
                  </div>
                  <div class="lookup-field-summary px-1">
                    <div class="lookup-field-summary px-1 min-width-0">
                      <p class="mb-0 w-100 text-truncate">
                        {{company.name}}
                      </p>
                    </div>
                  </div>
                </div>
              </ng-container>
            </dx-lookup>
            <div class="my-1 invalid-feedback d-block"
                 *ngIf="form.submitted && relatedToCompanyId.hasError('required')">
              Company is required
            </div>
          </div>
        </div>
      </div>
      <!-- End: Related to select -->
      <!-- Start: Claims select -->
      <div class="dx-fieldset">
        <div class="dx-field">
          <div class="dx-field-label">
            Claims
          </div>
          <div class="dx-field-value">
            <dx-drop-down-box placeholder="Select claims" displayExpr="id"
                              [dataSource]="claimDataSource" [showClearButton]="true"
                              [(value)]="userInvitation.claimIds">
              <div *dxTemplate="let data of 'content'">
                <dx-data-grid [dataSource]="claimDataSource"
                              [selection]="{ mode: 'multiple' }" [hoverStateEnabled]="true"
                              [paging]="{ enabled: true, pageSize: 10 }" [filterRow]="{ visible: false }"
                              [scrolling]="{ mode: 'virtual' }" [height]="345"
                              (onSelectionChanged)="claimSelectionChanged($event)">
                  <dxo-scrolling mode="virtual"></dxo-scrolling>
                  <dxo-paging [enabled]="true" [pageSize]="10"></dxo-paging>

                  <dxi-column dataField="id" dataType="number" [width]="75"></dxi-column>
                  <dxi-column dataField="claimType" caption="Claim" cellTemplate="claimTypeTemplate" dataType="number"></dxi-column>

                  <div *dxTemplate="let item of 'claimTypeTemplate';">
                    {{claimDisplayExpression(item.data)}}
                  </div>
                </dx-data-grid>
              </div>
            </dx-drop-down-box>
          </div>
        </div>
      </div>
      <!-- End: Claims select -->
      <!-- Start: Claim groups select -->
      <div class="dx-fieldset">
        <div class="dx-field">
          <div class="dx-field-label">
            Claim groups
          </div>
          <div class="dx-field-value">
            <dx-drop-down-box placeholder="Select claim groups" displayExpr="name"
                              [dataSource]="claimGroupDataSource" [showClearButton]="true"
                              [(value)]="userInvitation.claimGroupIds">
              <div *dxTemplate="let data of 'content'">
                <dx-data-grid [dataSource]="claimGroupDataSource"
                              [selection]="{ mode: 'multiple' }" [hoverStateEnabled]="true"
                              [paging]="{ enabled: true, pageSize: 10 }" [filterRow]="{ visible: false }"
                              [scrolling]="{ mode: 'virtual' }" [height]="345"
                              (onSelectionChanged)="claimGroupSelectionChanged($event)">
                  <dxo-scrolling mode="virtual"></dxo-scrolling>
                  <dxo-paging [enabled]="true" [pageSize]="10"></dxo-paging>

                  <dxi-column dataField="id" dataType="number" [width]="75"></dxi-column>
                  <dxi-column dataField="name" dataType="text"></dxi-column>
                </dx-data-grid>
              </div>
            </dx-drop-down-box>
          </div>
        </div>
      </div>
      <!-- End: Claim groups select -->
    </ng-container>

    <div class="dx-fieldset">
      <div class="d-flex flex-row mx-n2">
        <div class="px-2 w-50">
          <dx-button type="success" width="100%" text="Invite"
                     [useSubmitBehavior]="true">
          </dx-button>
        </div>
        <div class="px-2 w-50">
          <dx-button type="normal" text="Cancel" width="100%"
                     (onClick)="cancel()">
          </dx-button>
        </div>
      </div>
    </div>

  </form>
</dx-scroll-view>

